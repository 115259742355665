import { Chip, Flex, FormFeedback, FormLabel, Icon, Text } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import React, { useContext, useEffect } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import styled, { DefaultTheme } from 'styled-components';

import { ApplyFormContext } from '~/contexts/ApplyFormContext/ApplyFormContext';

const ERRORS = {
  invalidSize: 'invalidSize',
  invalidType: 'invalidType',
  valueMissing: 'valueMissing',
};

const getColor = ({ isDragActive, theme }: { isDragActive: boolean; theme: DefaultTheme }) => {
  if (isDragActive) {
    return theme.colors.primary.normal;
  }
  return theme.colors.border;
};

const getBgColor = ({ isDragActive, theme }: { isDragActive: boolean; theme: DefaultTheme }) => {
  if (isDragActive) {
    return theme.colors.primary.alpha;
  }
  return theme.palette.white;
};

const Container = styled.div`
  width: 100%;
`;

const DropZone = styled.div`
  flex: 1;
  outline: none;
  display: flex;
  cursor: pointer;
  border-width: 2px;
  text-align: center;
  border-style: dashed;
  justify-content: center;
  border-color: ${getColor};
  background-color: ${getBgColor};
  -webkit-tap-highlight-color: transparent;
  border-radius: ${({ theme }) => theme.radiuses.l};
  padding: ${({ theme }) => theme.space.component.x.xl};
  margin-top: ${({ theme }) => theme.space.component.x.xs};
  transition: ${({ theme }) => `all ${theme.transition.timings.fast} ${theme.transition.functions.easeFn}`};
`;

const CustomIcon = styled(Icon)`
  font-size: 49px;
  color: ${({ theme }) => theme.colors.primary.normal};
`;

const SelectSpan = styled.span`
  color: ${({ theme }) => theme.colors.primary.normal};
`;

const SubTitle = styled(Text)`
  color: ${({ theme }) => theme.colors.disabled.text};
  font-size: ${({ theme }) => theme.typography.fontSize.xs};
`;

const BottomContainer = styled.div`
  margin-top: ${({ theme }) => theme.space.layout.x.s};
  &:empty {
    display: none;
  }
`;

const getReason = (fileRejection: FileRejection): string => {
  switch (fileRejection?.errors[0].code) {
    case 'file-too-large':
    case 'file-too-small':
      return ERRORS.invalidSize;
    case 'file-invalid-type':
      return ERRORS.invalidType;
    default:
      return null;
  }
};

export const ResumeDropZone = () => {
  const { t } = useTranslation('all');
  const { form, loading } = useContext(ApplyFormContext);
  const [{ value: file, error, touched }, onChange, onValidityChange] = form.resumeField ?? [];

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
    },
    maxFiles: 1,
    maxSize: 1000000,
    disabled: loading,
    onDrop: (acceptedFiles, fileRejections) => {
      const newFile = acceptedFiles[0];
      onChange(newFile);
      onValidityChange({
        isValid: Boolean(newFile),
        reason: getReason(fileRejections[0]),
      });
    },
  });

  useEffect(() => {
    onValidityChange({
      isValid: false,
      reason: ERRORS.valueMissing,
    });
  }, []);

  const onRemove = () => {
    onChange(null);
    onValidityChange({
      isValid: false,
      reason: ERRORS.valueMissing,
    });
  };

  return (
    <Container>
      <FormLabel text={t('apply.form_resume_label')} />
      <DropZone {...getRootProps({ isDragActive })}>
        <Flex.Column space="s">
          <div>
            <CustomIcon name="folder-filled" />
          </div>
          <Text variant="body">
            {t('apply.step_unknown.dropzone.drop')}
            <SelectSpan>{t('apply.step_unknown.dropzone.select')}</SelectSpan>
          </Text>
          <SubTitle variant="body-light">{t('apply.step_unknown.dropzone.supported_files')}</SubTitle>
        </Flex.Column>
        <input {...getInputProps()} />
      </DropZone>
      <BottomContainer>
        {file ? (
          <Chip label={file.name} variant="grey" disabled={loading} onRemove={onRemove} />
        ) : (
          Boolean(error && touched) && <FormFeedback intent="danger">{error}</FormFeedback>
        )}
      </BottomContainer>
    </Container>
  );
};
