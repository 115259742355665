import { FormFeedback, FormField, FormLabel, Icon, Select } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC, useContext, useEffect, useRef, useState } from 'react';

import { ApplyFormContext } from '~/contexts/ApplyFormContext/ApplyFormContext';
import { useAsyncEffectIfMounted, usePredictions } from '~/hooks';
import { useGetAddressDetails } from '~/hooks/useGetAddressDetails';
import { Prediction } from '~/utils/address';

export const AddressField: FC = () => {
  const { t } = useTranslation('all');
  const ref = useRef<HTMLInputElement>();
  const [search, setSearch] = useState(null);

  const getDetails = useGetAddressDetails();
  const [predictions, , getPredictions] = usePredictions();

  const { form } = useContext(ApplyFormContext);
  const [{ error, touched }, onChange, onValidityChange] = form.addressField ?? [];

  const [value, setValue] = useState<Prediction>();

  useEffect(() => {
    ref.current.setAttribute('autocomplete', 'new-password');
    onValidityChange({ isValid: false, reason: 'valueMissing' });
  }, []);

  useAsyncEffectIfMounted(async () => {
    await getPredictions(search);
  }, [search]);

  const onSelect = async (prediction: Prediction) => {
    setValue(prediction);

    onValidityChange({ isValid: true, reason: null });
    let reason = null;

    const address = await getDetails(prediction.placeId);

    if (!address?.street || !address?.city || !address?.country) {
      reason = 'valueIncomplete';
    }
    onValidityChange({ isValid: !reason, reason });
    onChange(address);
  };

  return (
    <FormField
      style={{ flex: 1 }}
      label={<FormLabel text={t('apply.form_address_label')} />}
      control={
        <Select.Control
          required
          suffix={null}
          name="address"
          search={search}
          controlRef={ref}
          value={value}
          onChange={onSelect}
          onSearchChange={setSearch}
          errored={Boolean(error && touched)}
          placeholder={t('apply.form_address_placeholder')}
          prefix={<Icon name="location-outlined" size="m" />}
          formatLabel={(prediction: Prediction) => prediction.name}
        >
          {predictions?.map(prediction => (
            <Select.Option key={prediction.placeId} value={prediction} />
          ))}
        </Select.Control>
      }
      caption={error && touched && <FormFeedback intent="danger">{error}</FormFeedback>}
    />
  );
};
