import { PlaceData } from '@googlemaps/google-maps-services-js';
// eslint-disable-next-line no-restricted-imports
import axios from 'axios';

import { getGMapsApiUrl } from '~/config';
import { useCacheFromCallback } from '~/hooks/useCacheFromCallback';
import { useLocale } from '~/hooks/useLocale';
import { placeToAddressWithCityAndLocation } from '~/utils/address';

export const useGetAddressDetails = () => {
  const { country } = useLocale();
  return useCacheFromCallback(async placeId => {
    try {
      const { data: place } = await axios.post<PlaceData | null>(getGMapsApiUrl(country, 'getDetails'), {
        placeId,
        fields: ['address_components', 'geometry'],
      });
      if (place) {
        return placeToAddressWithCityAndLocation(place, country);
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  });
};
