import { FC, useContext, useEffect, useState } from 'react';

import { getRecaptchaSiteKey } from '~/config';
import { RecaptchaContext } from '~/contexts/RecaptchaContext/RecaptchaContext';
import { Field } from '~/hooks';

interface CaptchaFieldProps {
  field: Field<string>;
}

export const CaptchaField: FC<CaptchaFieldProps> = ({ field }) => {
  const [, onChange, onValidityChange, onTouch] = field;
  const [sitekey] = useState(getRecaptchaSiteKey());
  const recaptcha = useContext(RecaptchaContext);

  if (!sitekey) {
    return null;
  }

  useEffect(() => {
    onValidityChange({ isValid: false, reason: 'valueMissing' });
  }, []);

  useEffect(() => {
    if (recaptcha) {
      recaptcha.render('captcha', {
        sitekey,
        action: 'apply',
        callback: (token: string) => {
          onTouch();
          onValidityChange(token ? { isValid: true, reason: null } : { isValid: false, reason: 'valueMissing' });
          onChange(token || null);
        },
      });
    }
  }, [recaptcha]);

  return <div id="captcha" />;
};
