import { FormFeedback, FormField, FormLabel } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC, useContext } from 'react';

import PhoneInput from '~/components/Form/PhoneInput';
import { ApplyFormContext } from '~/contexts/ApplyFormContext/ApplyFormContext';

export const PhoneField: FC = () => {
  const { t } = useTranslation('all');
  const {
    form: { phoneField },
  } = useContext(ApplyFormContext);

  const value = phoneField?.[0]?.value;
  const error = phoneField?.[0]?.error;
  const touched = phoneField?.[0]?.touched;
  const onChange = phoneField?.[1];
  const onValidityChange = phoneField?.[2];

  return (
    <FormField
      style={{ flex: 1 }}
      label={<FormLabel text={t('apply.form_phone_label')} />}
      control={
        phoneField && (
          <PhoneInput
            required
            name="phone"
            value={value}
            error={error}
            touched={touched}
            onChange={onChange}
            onValidityChange={onValidityChange}
            placeholder={t('apply.form_phone_placeholder')}
          />
        )
      }
      caption={error && touched && <FormFeedback intent="danger">{error}</FormFeedback>}
    />
  );
};
