import { Button, Flex, Heading, Text } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useEffect } from 'react';
import styled from 'styled-components';

import qrcode from '~/../public/images/qr_download.png';
import { getAppLinkMissionApplied } from '~/config/links';
import { useGoldenBees } from '~/hooks/useGoldenBees';

import { DesktopOnly, MobileOnly } from '../../Styled';
import { ApplyCloseButtonProps } from '../types';

const ButtonMargin = styled(Button)`
  margin-top: 5rem;
`;

const TextNormal = styled(Text)`
  color: ${({ theme }) => theme.colors.info?.normal};
  display: block;
`;

interface ApplyAppliedBodyProps extends ApplyCloseButtonProps {}

export const ApplyAppliedBody: FC<ApplyAppliedBodyProps> = () => {
  const { t } = useTranslation('all');
  const link = getAppLinkMissionApplied();

  const goldenBees = useGoldenBees();

  useEffect(() => {
    goldenBees();
  }, []);

  return (
    <>
      <MobileOnly>
        <Text variant="body-light" textAlign="center">
          {t('apply.subtitle_download_app')}
        </Text>
        <Link href={link} passHref legacyBehavior>
          <ButtonMargin size="l" fullWidth variant="primary" role="link" data-href={link}>
            {t('apply.download_app')}
          </ButtonMargin>
        </Link>
      </MobileOnly>

      <DesktopOnly>
        <Flex.Row space="m" justifyContent="center">
          <Image
            src={qrcode}
            width={200}
            height={200}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            alt=""
          />
        </Flex.Row>

        <Heading variant="heading-2" textAlign="center">
          {t('apply.subtitle_download_app')}
        </Heading>

        <TextNormal textAlign="center" variant="body-lighter">
          {t('apply.congrat_1_steps')}
        </TextNormal>
        <TextNormal textAlign="center" variant="body-lighter">
          {t('apply.congrat_2_steps')}
        </TextNormal>
        <TextNormal textAlign="center" variant="body-lighter">
          {t('apply.congrat_3_steps')}
        </TextNormal>
      </DesktopOnly>
    </>
  );
};
