import { Grid } from '@iziwork/design-system';
import { TAddress } from '@iziwork/l10n';
import { FC, FormEvent, useContext, useState } from 'react';
import { useAsyncFn } from 'react-use';

import type { JobOfferWithJobPost } from '~/business/JobOffer/types';
import { postWebParentMissionApply, WebParentMissionApply } from '~/business/WebParentMissionApply';
import { getRecaptchaInvisibleSiteKey } from '~/config/google';
import { ApplyFormContext } from '~/contexts/ApplyFormContext/ApplyFormContext';
import { ModalStates } from '~/contexts/ApplyFormContext/enums';
import { RecaptchaContext } from '~/contexts/RecaptchaContext/RecaptchaContext';
import { TrackingContext } from '~/contexts/TrackingContext';
import { useLocale, useSource } from '~/hooks';
import { toBase64 } from '~/utils';

import { AddressField, FirstnameField, LastnameField, PhoneField, ResumeDropZone } from './fields';

export interface ApplyUnknownValues {
  firstname: string;
  lastname: string;
  phone: string;
  address: TAddress;
  resume: File;
  recaptcha?: string;
}

export interface ApplyUnknownBodyProps extends JobOfferWithJobPost {
  setState: (state: ModalStates) => void;
}

export const ApplyUnknownBody: FC<ApplyUnknownBodyProps> = ({ setState, jobOffer, jobPost }) => {
  const { form, setError, setLoading } = useContext(ApplyFormContext);
  const { country } = useLocale();
  const source = useSource();
  const { trackApplicationSubmitted } = useContext(TrackingContext);
  const [sitekey] = useState(getRecaptchaInvisibleSiteKey());
  const gRecaptcha = useContext(RecaptchaContext);

  const [, applyUnknownWorker] = useAsyncFn(
    async (data: ApplyUnknownValues) => {
      try {
        setLoading(true);
        const recaptcha = await getRecaptcha();

        const postData: WebParentMissionApply = {
          source,
          country,
          jobOfferId: jobOffer.id,
          firstname: data.firstname,
          lastname: data.lastname,
          address: data.address,
          phone: data.phone,
          file: await toBase64(data.resume),
          fileName: data.resume.name,
          email: form.emailField[0].value,
          recaptcha,
        };

        await postWebParentMissionApply(postData);

        trackApplicationSubmitted({
          email: form.emailField[0].value,
          refId: jobPost.refId,
          jobTitle: jobOffer.title,
        });

        setState(ModalStates.APPLIED);
      } catch (error) {
        setState(ModalStates.UNKNOWN);
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [source, country, jobOffer],
  );

  async function getRecaptcha(): Promise<any> {
    return gRecaptcha?.execute(sitekey, { action: 'apply' });
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    form.touchAll();
    if (
      !form.firstnameField?.[0]?.error &&
      !form.lastnameField?.[0]?.error &&
      !form.phoneField?.[0]?.error &&
      !form.addressField?.[0]?.error &&
      !form.resumeField?.[0]?.error
    ) {
      await applyUnknownWorker({
        firstname: form.firstnameField?.[0]?.value,
        lastname: form.lastnameField?.[0]?.value,
        phone: form.phoneField?.[0]?.value,
        address: form.addressField?.[0]?.value,
        resume: form.resumeField?.[0]?.value,
      });
    }
  };

  return (
    <form noValidate id="apply_unknown_form" onSubmit={onSubmit}>
      <Grid.Wrapper>
        <Grid.Cell xs={1} m={1 / 2}>
          <FirstnameField />
        </Grid.Cell>

        <Grid.Cell xs={1} m={1 / 2}>
          <LastnameField />
        </Grid.Cell>

        <Grid.Cell xs={1} m={1 / 2}>
          <PhoneField />
        </Grid.Cell>

        <Grid.Cell xs={1} m={1 / 2}>
          <AddressField />
        </Grid.Cell>

        <Grid.Cell xs={1}>
          <ResumeDropZone />
        </Grid.Cell>
      </Grid.Wrapper>
    </form>
  );
};
