import Script from 'next/script';
import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

import { getRecaptchaInvisibleSiteKey } from '~/config';
import { getEnv } from '~/utils/env';

export type RecaptchaContextPropsType = {
  children: ReactNode;
};

export const RecaptchaContext = createContext<any>(null);

export default function RecaptchaContextProvider({ children }: RecaptchaContextPropsType) {
  const url = useMemo(() => getEnv('NEXT_PUBLIC_GOOGLE_RECAPTCHA_URL'), []);
  const siteKey = useMemo(() => getRecaptchaInvisibleSiteKey(), []);
  const [recaptcha, setRecaptcha] = useState(globalThis.grecaptcha?.enterprise);
  const [loaded, setLoaded] = useState(false);

  const canLoad = Boolean(url) && Boolean(siteKey) && !recaptcha;

  useEffect(() => {
    if (canLoad) {
      // prevent loading script before the function exists
      globalThis.onGRecaptchaLoad = () => {
        globalThis.grecaptcha?.enterprise?.ready?.(() => {
          setRecaptcha(globalThis.grecaptcha.enterprise);
        });
      };
      setLoaded(true);
    }
  }, []);

  return (
    <>
      {canLoad && loaded && <Script src={`${url}?onload=onGRecaptchaLoad&render=${siteKey}`} async defer />}
      <RecaptchaContext.Provider value={recaptcha}>{children}</RecaptchaContext.Provider>
    </>
  );
}
