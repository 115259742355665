import { FormFeedback, FormField, FormLabel, TextInput } from '@iziwork/design-system';
import useTranslation from 'next-translate/useTranslation';
import { FC, useContext } from 'react';

import { ApplyFormContext } from '~/contexts/ApplyFormContext/ApplyFormContext';

export const FirstnameField: FC = () => {
  const { t } = useTranslation('all');
  const { form } = useContext(ApplyFormContext);
  const [values, onChange, onValidityChange] = form.firstnameField ?? [];

  return (
    <FormField
      style={{ flex: 1 }}
      label={<FormLabel text={t('apply.form_firstname_label')} />}
      control={
        <TextInput
          required
          name="firstname"
          value={values?.value}
          onChange={onChange}
          onValidityChange={onValidityChange}
          placeholder={t('apply.form_firstname_placeholder')}
          errored={!!values.error && values?.touched}
        />
      }
      caption={
        values?.error && values?.touched ? <FormFeedback intent="danger">{values?.error}</FormFeedback> : undefined
      }
    />
  );
};
