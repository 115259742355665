import { CustomFormatInput } from '@iziwork/design-system';
import { formatPhoneNumber, PhoneNumberType, TAvailableCountry } from '@iziwork/l10n';
import { KeyboardEventHandler } from 'react';

import { useLocale } from '~/hooks';

type PhoneInputProps = {
  name?: string;
  value: string;
  touched: boolean;
  required?: boolean;
  error: string | null;
  placeholder?: string;
  onKeyDown?: KeyboardEventHandler;
  onChange: (value: string) => void;
  onValidityChange: (data: { isValid: boolean; reason: string }) => void;
};

export default function PhoneInput({
  name,
  value,
  error,
  touched,
  required,
  onChange,
  onKeyDown,
  placeholder,
  onValidityChange,
}: PhoneInputProps) {
  const { country } = useLocale();

  return (
    <CustomFormatInput
      name={name}
      errored={Boolean(touched && error)}
      onChange={input => {
        const { phoneNumber, isValid } = formatPhoneNumber({
          phoneNumber: input,
          format: PhoneNumberType.NATIONAL,
          countryCode: country.toUpperCase() as TAvailableCountry,
        });

        if (phoneNumber || touched) {
          onChange(phoneNumber);
        }

        if (!phoneNumber && !required) {
          onValidityChange({ isValid: true, reason: null });
        } else {
          onValidityChange({ isValid, reason: phoneNumber ? 'typeMismatch' : 'valueMissing' });
        }
      }}
      value={value}
      type="tel"
      placeholder={placeholder}
      format={parsedValue => parsedValue}
      parse={phoneNumber => phoneNumber}
      acceptChar={(char: string) => /[ 0-9+\-()]/.test(char)}
      onKeyDown={onKeyDown}
    />
  );
}
