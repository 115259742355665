import { EmailInput, Flex, FormFeedback, FormField, FormLabel } from '@iziwork/design-system';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { FC, FormEvent, useContext, useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { JobOfferWithJobPost } from '~/business/JobOffer/types';
import {
  postWebParentMissionApplyIfWorkerExists,
  WebParentMissionApplyIfWorkerExistsParams,
} from '~/business/WebParentMissionApplyIfWorkerExists';
import { CaptchaField } from '~/components/ApplyForm/CaptchaField';
import { MessageInfo } from '~/components/MessageInfo';
import { getRecaptchaSiteKey } from '~/config/google';
import { ApplyFormContext } from '~/contexts/ApplyFormContext/ApplyFormContext';
import { ModalStates } from '~/contexts/ApplyFormContext/enums';
import { TrackingContext } from '~/contexts/TrackingContext';
import { useLocale, useSource } from '~/hooks';
import { useGetIziworkPublicUrl } from '~/hooks/useGetIziworkPublicUrl';
import { getLocalStorage } from '~/utils/storage';

export interface ApplyStartValues {
  email: string;
  captcha: string;
}

export interface ApplyStartBodyProps extends JobOfferWithJobPost {
  setState: (state: ModalStates) => void;
}

export const APPLICATION_EMAIL = 'application_email';

export const ApplyStartBody: FC<ApplyStartBodyProps> = ({ setState, jobOffer, jobPost }) => {
  const source = useSource();
  const { country } = useLocale();
  const lStorage = getLocalStorage();
  const { t } = useTranslation('all');
  const getIziworkPublicUrl = useGetIziworkPublicUrl();
  const { setError, setLoading, form } = useContext(ApplyFormContext);

  const captchaField = form.captchaField;
  const [emailValues, onEmailChange, onEmailValidityChange, onEmailTouch] = form.emailField || [];
  const [captchaValues, , , onCaptchaTouch] = captchaField;

  const { trackEmailSubmitted, trackApplicationSubmitted } = useContext(TrackingContext);

  const [, applyIfWorkerExists] = useAsyncFn(
    async ({ email, captcha: recaptcha }: ApplyStartValues) => {
      try {
        setLoading(true);

        const postData: WebParentMissionApplyIfWorkerExistsParams = {
          source,
          country,
          email,
          recaptcha,
          jobOfferId: jobOffer.id,
        };

        const result = await postWebParentMissionApplyIfWorkerExists(postData);

        const trackingParams = { refId: jobPost.refId, jobTitle: jobOffer.title, email };
        trackEmailSubmitted(trackingParams);
        if (result) {
          trackApplicationSubmitted(trackingParams);
        }
        setState(result ? ModalStates.APPLIED : ModalStates.UNKNOWN);

        return result;
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    },
    [source, country, jobOffer],
  );

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onEmailTouch();
    onCaptchaTouch();
    if (!emailValues.error && !(captchaValues.error && getRecaptchaSiteKey())) {
      await applyIfWorkerExists({
        email: emailValues.value,
        captcha: captchaValues.value,
      });
    }
    if (lStorage) {
      lStorage.setItem(APPLICATION_EMAIL, emailValues.value);
    }
  };

  useEffect(() => {
    const defaultEmail = lStorage ? lStorage.getItem(APPLICATION_EMAIL) : undefined;
    if (defaultEmail) onEmailChange(defaultEmail);
  }, []);

  return (
    <form noValidate id="apply_start_form" onSubmit={onSubmit}>
      <Flex.Column space="l">
        <FormField
          label={<FormLabel text={t('apply.form_email_label')} />}
          control={
            <EmailInput
              required
              name="email"
              value={emailValues.value}
              onChange={onEmailChange}
              onValidityChange={onEmailValidityChange}
              placeholder={t('apply.form_email_placeholder')}
              errored={!!emailValues.error && emailValues.touched}
            />
          }
          caption={
            emailValues.error && emailValues.touched ? (
              <FormFeedback intent="danger">{emailValues.error}</FormFeedback>
            ) : undefined
          }
        />
        <FormField
          control={<CaptchaField field={captchaField} />}
          caption={
            captchaValues.error && captchaValues.touched && getRecaptchaSiteKey() ? (
              <FormFeedback intent="danger">{captchaValues.error}</FormFeedback>
            ) : undefined
          }
        />
        <MessageInfo variant="body-light">
          <Trans
            i18nKey="all:apply.message_info_accept_tcu"
            components={{
              cpLink: <a href={getIziworkPublicUrl('confidentiality')} target="_blank" />,
              tcuLink: <a href={getIziworkPublicUrl('usageConditions')} target="_blank" />,
            }}
          />
        </MessageInfo>
      </Flex.Column>
    </form>
  );
};
